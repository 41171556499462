export default {
    ENVIRONMENT: "development",
    ENVIRONMENTS: {
        production: {
            API_URL: "https://api-connect.digitalrange.com",
            BASE_IMG_PATH: "https://s3.us-east-2.amazonaws.com/digital-range-connect"
        },
        development: {
            API_URL: "https://api-connect.digitalrange.com",
            BASE_IMG_PATH: "https://s3.us-east-2.amazonaws.com/digital-range-connect"
        }
    },
};