import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import moment from "moment";
import anchorme from "anchorme";
import _ from "underscore";
import {
    ChatMessageType,
    MessageSenderType,
    PostMessageType,
    MediaType,
    AllowedDocumentType,
    AllowedImageType
} from "../../constants";
import PostMessageContext from "../../context/PostMessageContext";

import "./RCChatWidgetMainView.css";
import "./RCChatWidgetMainViewbk.css";
import { RCChatWidgetMainViewChatField } from "../RCChatWidgetMainView";

import Loader from "../../component/Loader/Loader";
import { AngleDownDouble, CrossWhite, File } from "../../assets/Svg-icons";
import { concatBaseMediaUrl } from "../../utils/imageUtils";
import pdfSvg from "../../assets/images/file-types/pdf.svg";
import mswordSvg from "../../assets/images/file-types/doc.svg";
import pptSvg from "../../assets/images/file-types/ppt.svg";
import xlsSvg from "../../assets/images/file-types/xls.svg";
import txtSvg from "../../assets/images/file-types/txt.svg";
import filePng from "../../assets/images/file-png.png";
import aiSvg from "../../assets/images/file-types/ai.svg";
import jsSvg from "../../assets/images/file-types/js.svg";
import jsonSvg from "../../assets/images/file-types/js.svg";
import cssSvg from "../../assets/images/file-types/css.svg";
import csvSvg from "../../assets/images/file-types/csv.svg";
import epsSvg from "../../assets/images/file-types/eps.svg";
import exeSvg from "../../assets/images/file-types/exe.svg";
import htmlSvg from "../../assets/images/file-types/html.svg";
import movSvg from "../../assets/images/file-types/mov.svg";
import mp3Svg from "../../assets/images/file-types/mp3.svg";
import mp4Svg from "../../assets/images/file-types/mp4.svg";
import phpSvg from "../../assets/images/file-types/php.svg";
import psdSvg from "../../assets/images/file-types/psd.svg";
import rarSvg from "../../assets/images/file-types/rar.svg";
import svgSvg from "../../assets/images/file-types/svg.svg";
import wavSvg from "../../assets/images/file-types/wav.svg";
import zipSvg from "../../assets/images/file-types/zip.svg";

import { validateEmail } from "../../utils/validateUtils";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { scrollToBottom } from "../../utils/scrollUtils";
import { colorIsLight } from "../../utils/colorUtil";

const RCChatWidgetMainViewContent = (props) => {
    //  const [show, setShow] = useState();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [inputFocus, setInputFocus] = useState(false);
    const [isInputCorrect, setIsInputCorrect] = useState(false);
    const [file, setFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [imageUploading, setImageUploading] = useState(false);
    const ChatFieldRef = useRef();
    const [automationIntroMessage, setAutomationIntroMessage] = useState("");

    const {
        messages,
        messageLoading,
        hasMore,
        scrollSnapshot,
        automation,
        showOptionsContainer,
        onAutomationFormSubmit,
        onAutomationMediaDownload,
        chatClosed,
        agentData,
        agentTyping,
        handleUserTyping,
        showAutomationForm,
        handleShowOptionsContainer,
        automationFormSubmitEver,
    } = props;

    const { IframePostMessage, widgetSettings } = useContext(PostMessageContext);

    useEffect(() => {
        validateFormInput(checkForAutomationInput());
    }, [name, email, phone]);


    const onFileChange = (data = {}) => {
        if ("file" in data) {
            setFile(data.file);
        }
        if ("fileType" in data) {
            setFileType(data.fileType);
        }
        if ("imagePreview" in data) {
            setImagePreview(data.imagePreview);
        }
        if ("imageUploading" in data) {
            setImageUploading(data.imageUploading);
        }
    };

    const handleImageClearClick = () => {
        setFile(null);
        setFileType(null);
        setImagePreview(null);
        ChatFieldRef.current.clearSelectedFile();
    };

    const handleScroll = (e) => {
        if (hasMore) {
            const { messageLoading } = props;
            const isTop = e.target.scrollTop < 1;
            if (isTop && !messageLoading) {
                const container = document.getElementById("swiftchat-scroll-div");
                scrollSnapshot.current = {
                    prevScrollHeight: container.scrollHeight,
                    prevScrollTop: container.scrollTop
                };
                IframePostMessage({
                    Message: PostMessageType.LoadMoreMessages,
                    // Data: true,
                    Data: { top: true, messageId: messages[messages.length - 1].Id }
                });
            }
        }
    };

    const convertTimestampToDate = (time, index, previousMessage) => {
        let date;
        let showDate = true;

        if (previousMessage && previousMessage.CreatedAt) {
            let isPreviousDay = moment(previousMessage.CreatedAt).isBefore(time, "day");
            let isSameMinute = moment(previousMessage.CreatedAt).minute() === moment(time).minute();

            if (isSameMinute) {
                showDate = false;
            }
            if (isPreviousDay) {
                date = moment(time).format("L");
            }
        } else {
            // if (!messageDataAvailable) {
            //     date = moment(time).format("LLL");
            // }
        }

        if (!date) {
            date = moment(time).format("LT");
        }
        return showDate ? (
            <div
                className="reply-date"
                //  style={{ color: widgetSettings.Appearance.LabelTextColor }}
                style={{ color: "#84868d" }}
            >
                {date}
            </div>
        ) : null;
    };

    const replaceUrlWithHtmlTags = (content) => {
        let text = anchorme(content, {
            attributes: [{ name: "target", value: "blank" }]
        });
        text = _.unescape(text);
        return text;
    };

    const handleAutomationMediaDownload = (mediaId, chatId) => {
        console.log("AUTOMATION MEDIA DOWNLOAD", mediaId);
        const automationMediaObj = {
            MediaId: mediaId,
            ChatId: chatId
        };
        onAutomationMediaDownload(automationMediaObj);
    };

    const checkForAutomationInput = () => {
        let automationObj = null;
        if (automation.Automation) {
            automationObj = automation;
        }
        if (automationObj) {
            let { TakeUsersName, TakeUsersEmail, TakeUsersPhone } = automationObj.Automation;
            let obj = {};
            obj.nameOnly = TakeUsersName && !TakeUsersEmail && !TakeUsersPhone;
            obj.emailOnly = !TakeUsersName && TakeUsersEmail && !TakeUsersPhone;
            obj.phoneOnly = !TakeUsersName && !TakeUsersEmail && TakeUsersPhone;
            obj.name_email = TakeUsersName && TakeUsersEmail && !TakeUsersPhone;
            obj.name_phone = TakeUsersName && !TakeUsersEmail && TakeUsersPhone;
            obj.email_phone = !TakeUsersName && TakeUsersEmail && TakeUsersPhone;
            obj.name_email_phone = TakeUsersName && TakeUsersEmail && TakeUsersPhone;
            for (let key in obj) {
                if (obj[key]) {
                    return key;
                }
            }
        }
    };

    const validateFormInput = (automationFormOptions) => {
        let automationObj = null;
        if (automation.Automation) {
            automationObj = automation;
        }
        if (automationObj) {
            let nameOnly = "nameOnly";
            let emailOnly = "emailOnly";
            let phoneOnly = "phoneOnly";
            let name_email = "name_email";
            let name_phone = "name_phone";
            let email_phone = "email_phone";
            let name_email_phone = "name_email_phone";

            switch (automationFormOptions) {
                case nameOnly:
                    if (name.trim()) {
                        setIsInputCorrect(true);
                    } else {
                        setIsInputCorrect(false);
                    }
                    break;
                case emailOnly:
                    if (email.trim()) {
                        setIsInputCorrect(true);
                    } else {
                        setIsInputCorrect(false);
                    }
                    break;
                case phoneOnly:
                    if (phone.trim()) {
                        setIsInputCorrect(true);
                    } else {
                        setIsInputCorrect(false);
                    }
                    break;
                case name_email:
                    if (name.trim() && email.trim()) {
                        setIsInputCorrect(true);
                    } else {
                        setIsInputCorrect(false);
                    }
                    break;
                case name_phone:
                    if (name.trim() && phone.trim()) {
                        setIsInputCorrect(true);
                    } else {
                        setIsInputCorrect(false);
                    }
                    break;
                case email_phone:
                    if (email.trim() && phone.trim()) {
                        setIsInputCorrect(true);
                    } else {
                        setIsInputCorrect(false);
                    }
                    break;
                case name_email_phone:
                    if (name.trim() && email.trim() && phone.trim()) {
                        setIsInputCorrect(true);
                    } else {
                        setIsInputCorrect(false);
                    }
                    break;
            }
        }
    };

    const handleInputChange = (e) => {
        let name = e.target.dataset.name;
        // this.setState({ [name]: e.target.value, emailError: false });
        switch (name) {
            case "name":
                setName(e.target.value);
                break;
            case "email":
                setEmail(e.target.value);
                break;
            case "phone":
                setPhone(e.target.value);
                break;
        }
    };

    const handleContainerSubmit = (e) => {
        e.preventDefault();

        let automationObj = null;
        if (automation.Automation) {
            automationObj = automation;
        }
        if (automationObj) {
            let formObj = {};
            if (automationObj.Automation) formObj["AutomationId"] = automationObj.Automation.Id;
            if (name) formObj["Name"] = name;
            if (email) formObj["Email"] = email;
            if (phone) formObj["Phone"] = phone;
            let formData = formObj;
            onAutomationFormSubmit(formData);
        }
    };


    const renderOptionsContainer = () => {
        let automationObj = automation;

        if (automationObj) {
            if (
                automationObj.Automation.TakeUsersName ||
                automationObj.Automation.TakeUsersEmail ||
                automationObj.Automation.TakeUsersPhone
            ) {
                return (
                    <form
                        onSubmit={handleContainerSubmit}
                        className="form-container"
                        autocomplete="off" 
                    >
                        <div
                            className={`fixed animated faster ${!showOptionsContainer ? "fadeOut" : "fadeIn"
                                }`}
                            id="email-modal"
                        >
                            <div
                                className={`email-modal ${showOptionsContainer ? "fadeInUp" : "fadeOutDown"
                                    } animated faster`}
                                id="email-modal-animated"
                            >
                                <div className="email-modal-content-wrapper">
                                    <div
                                        className="email-modal-content-heading"
                                        style={{
                                            backgroundColor: widgetSettings.BackgroundColor
                                        }}
                                    >
                                        {/* <h1 className="width-100">Get Notified</h1> */}
                                        {automationObj.Automation.FormHeader ? (<h1 className="width-100" style={{ wordBreak: "break-word" }}>{automationObj?.Automation?.FormHeader}</h1>) : null}
                                    </div>
                                    <div className="email-modal-content">
                                        {automationObj.Automation.TakeUsersName ? (
                                            <div className="email-modal-input">
                                                <input
                                                    // className={this.state.emailError ? "error" : ""}
                                                    type="text"
                                                    placeholder="Name"
                                                    data-name="name"
                                                    onChange={handleInputChange}
                                                    value={name}
                                                    // required
                                                />
                                            </div>
                                        ) : null}
                                        {automationObj.Automation.TakeUsersEmail ? (
                                            <div className="email-modal-input">
                                                <input
                                                    // className={this.state.emailError ? "error" : ""}
                                                    type="email"
                                                    placeholder="Email"
                                                    data-name="email"
                                                    onChange={handleInputChange}
                                                    value={email}
                                                    // required
                                                />
                                            </div>
                                        ) : null}
                                        {automationObj.Automation.TakeUsersPhone ? (
                                            <div className="email-modal-input">
                                                {/* <input
                                            // className={this.state.emailError ? "error" : ""}
                                            type="text"
                                            placeholder="SMS"
                                            data-name="phone"
                                            onChange={handleInputChange}
                                            value={phone}
                                            required
                                        /> */}
                                                <div className={`field-container ${inputFocus ? "field-selected" : null}`}>
                                                    <div className={`font-15px ${(inputFocus || phone.length > 0) ? null : "lda-text-gray"}`}>+</div>
                                                    <PhoneInput
                                                        name="phone"
                                                        prefix=""
                                                        value={phone}
                                                        onBlur={() => setInputFocus(false)}
                                                        onFocus={() => setInputFocus(true)}
                                                        containerClass="custom-input-field"
                                                        onChange={(phone) => setPhone(phone)}
                                                        placeholder="Phone Number"
                                                        inputStyle={{
                                                            color: "#000000",
                                                            fontSize: "1.5rem"
                                                        }}
                                                        // required
                                                        country={'us'}
                                                    // onlyCountries= {['us']}
                                                    // countryCodeEditable={false}
                                                    />
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className="email-modal-btn-wrapper custom-btn">
                                            <button
                                                type="submit"
                                                className="btn email-btn margin-top send"
                                                style={{
                                                    // backgroundColor:
                                                    //     widgetSettings.Appearance.TitleBarColor,
                                                    // border: widgetSettings.Appearance.TitleBarColor
                                                    backgroundColor: widgetSettings.ButtonColor,
                                                    border: `1px solid ${widgetSettings.ButtonColor}`,
                                                    borderRadius: "5px"
                                                }}
                                                // disabled={isInputCorrect ? false : true}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    const renderMessageContent = (msgData, isAgent = false, automatedResponse = null) => {
        const messageColor = isAgent ? "#FFFF" : "#FFFF";

        const messageBackgroundColor = isAgent ? "#f6f7f8" : widgetSettings?.BackgroundColor;

        if (!!msgData.MediaUrl) {
            let url = msgData.MediaUrl;

            let splittedUrl = url.split(".");
            let MediaUrlType = splittedUrl[splittedUrl.length - 1];
            MediaUrlType = MediaUrlType.includes("gif") ? "gif" : MediaUrlType;
            MediaUrlType = MediaUrlType.toLowerCase();

            if (AllowedImageType.includes(MediaUrlType)) {
                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: `${isAgent ? "flex-start" : "flex-end"}`
                        }}
                    >
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            <img
                                src={url}
                                onClick={() => {
                                    window.open(url);
                                }}
                                alt="attachment"
                                className={`${msgData.Content
                                        ? "chat-message-image-content"
                                        : "chat-message-image"
                                    }`}
                            />
                        </a>
                        {msgData.Content ? (
                            <div
                                className="chat-message"
                                style={{
                                    backgroundColor: messageBackgroundColor,
                                    boxShadow: "0 2px 4px 2px #e0e0e0"
                                }}
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: replaceUrlWithHtmlTags(msgData.Content)
                                    }}
                                />
                                {/* {msgData.Content} */}
                                {/* {msgData.Content && <p className="message-caption">{msgData.Content}</p>} */}
                            </div>
                        ) : null}
                    </div>
                );
            } else if (AllowedDocumentType.includes(MediaUrlType)) {
                let documentImage;
                switch (MediaUrlType) {
                    case "pdf":
                        documentImage = pdfSvg;
                        break;
                    case "docx":
                        documentImage = mswordSvg;
                        break;
                    case "doc":
                        documentImage = mswordSvg;
                        break;
                    case "msword":
                        documentImage = mswordSvg;
                        break;
                    case "ppt":
                        documentImage = pptSvg;
                        break;
                    case "pptx":
                        documentImage = pptSvg;
                        break;
                    case "xlsx":
                        documentImage = xlsSvg;
                        break;
                    case "xls":
                        documentImage = xlsSvg;
                        break;
                    case "txt":
                        documentImage = txtSvg;
                        break;
                    case "ai":
                        documentImage = aiSvg;
                        break;
                    case "javascript":
                        documentImage = jsSvg;
                        break;
                    case "js":
                        documentImage = jsSvg;
                        break;
                    case "json":
                        documentImage = jsonSvg;
                        break;
                    case "css":
                        documentImage = cssSvg;
                        break;
                    case "csv":
                        documentImage = csvSvg;
                        break;
                    case "eps":
                        documentImage = epsSvg;
                        break;
                    case "exe":
                        documentImage = exeSvg;
                        break;
                    case "html":
                        documentImage = htmlSvg;
                        break;
                    case "mov":
                        documentImage = movSvg;
                        break;
                    case "mp3":
                        documentImage = mp3Svg;
                        break;
                    case "mpeg":
                        documentImage = mp3Svg;
                        break;
                    case "mp4":
                        documentImage = mp4Svg;
                        break;
                    case "php":
                        documentImage = phpSvg;
                        break;
                    case "psd":
                        documentImage = psdSvg;
                        break;
                    case "rar":
                        documentImage = rarSvg;
                        break;
                    case "svg":
                        documentImage = svgSvg;
                        break;
                    case "wav":
                        documentImage = wavSvg;
                        break;
                    case "zip":
                        documentImage = zipSvg;
                        break;
                    default:
                        documentImage = filePng;
                        break;
                }
                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: `${isAgent ? "flex-start" : "flex-end"}`
                        }}
                    >
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            <img
                                src={documentImage}
                                onClick={() => {
                                    window.open(url);
                                }}
                                alt="attachment"
                                className={`${msgData.Content
                                        ? "chat-message-document-content"
                                        : "chat-message-document"
                                    }`}
                            />
                        </a>
                        {msgData.Content ? (
                            <div
                                className="chat-message"
                                style={{
                                    backgroundColor: messageBackgroundColor,
                                    boxShadow: "0 2px 4px 2px #e0e0e0"
                                }}
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: replaceUrlWithHtmlTags(msgData.Content)
                                    }}
                                />
                                {/* {msgData.Content} */}
                                {/* {msgData.Content && <p className="message-caption">{msgData.Content}</p>} */}
                            </div>
                        ) : null}
                    </div>
                );
            }
        } else {
            return typeof msgData.Content === "string" ? (
                <div
                    dangerouslySetInnerHTML={{
                        __html: replaceUrlWithHtmlTags(msgData.Content)
                    }}
                />
            ) : msgData.Content ? (
                msgData.Content
            ) : (
                automatedResponse
            );
        }
    };

    const renderTypingHint = () => {
        return (
            <div className="reply typing-indicator" style={{ padding: 0 }}>
                <div className="reply-wrapper">
                    <div className="admin reply-content">
                        <div
                            className="chat-message"
                            style={{
                                backgroundColor: "#f6f7f8",
                                boxShadow: "0 2px 4px 2px #e0e0e0"
                            }}
                        >
                            <div className="typing-animation">
                                <span
                                    className="dot"
                                    style={{
                                        backgroundColor: "#a7b9c5"
                                    }}
                                ></span>
                                <span
                                    className="dot"
                                    style={{
                                        backgroundColor: "#a7b9c5"
                                    }}
                                ></span>
                                <span
                                    className="dot"
                                    style={{
                                        backgroundColor: "#a7b9c5"
                                    }}
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderUploadedFile = useMemo(() => {
        let fileUI = null;

        if (file && fileType) {
            switch (fileType) {
                case MediaType.IMAGE:
                    fileUI = (
                        <div className="image-preview-container">
                            <img
                                className="cross-icon"
                                src={CrossWhite}
                                alt="cross-icon"
                                onClick={handleImageClearClick}
                            />
                            {/* {!imageUploading ? (
                                <img
                                    className="image-preview"
                                    src={imagePreview}
                                    alt="uploaded-preview"
                                />
                            ) : (
                                <Loader color="gray" />
                            )} */}

                            <img
                                className="image-preview"
                                src={imagePreview}
                                alt="uploaded-preview"
                            />
                            {imageUploading ? <Loader color="gray" imageLoader={true} /> : null}
                        </div>
                    );
                    break;
                case MediaType.DOCUMENT:
                    fileUI = (
                        <div className="image-preview-container file">
                            <div className="file-preview" title={file.name}>
                                <img
                                    className="cross-icon file"
                                    src={CrossWhite}
                                    alt="cross-icon"
                                    onClick={handleImageClearClick}
                                />
                                {/* {!imageUploading ? (
                                    <>
                                        <img
                                            className="image-preview file"
                                            src={File}
                                            alt="file-icon"
                                        />
                                        <div className="file-name">{file.name}</div>
                                    </>
                                ) : (
                                    <Loader color="gray" />
                                )} */}
                                <>
                                    <img
                                        className="image-preview file"
                                        src={File}
                                        alt="file-icon"
                                    />
                                    <div className="file-name">{file.name}</div>
                                </>
                                {imageUploading ? <Loader color="gray" imageLoader={true} /> : null}
                            </div>
                        </div>
                    );
                    break;
                case MediaType.UNSUPPORTED:
                    fileUI = (
                        <div className="image-preview-container file">
                            <div className="file-preview" title={"invalid/empty file"}>
                                <img
                                    className="cross-icon file"
                                    src={CrossWhite}
                                    alt="cross-icon"
                                    onClick={handleImageClearClick}
                                />
                                {!imageUploading ? (
                                    <>
                                        <img
                                            className="image-preview file"
                                            src={File}
                                            alt="file-icon"
                                        />
                                        <div style={{ color: "red" }} className="file-name">
                                            {"invalid/empty file"}
                                        </div>
                                    </>
                                ) : (
                                    <Loader color="gray" />
                                )}
                            </div>
                        </div>
                    );
                    break;
                default:
                    return null;
            }

            setTimeout(() => {
                var objDiv = document.getElementById("swiftchat-scroll-div");
                if (objDiv) {
                    objDiv.scrollTop = objDiv.scrollHeight;
                }
            }, 0);

        }
        return fileUI;
    },[file, fileType, imagePreview, imageUploading]);

    const renderMessage = () => {
        let msgs = [...messages];

        msgs.reverse();
        // console.log("QQQQQQQQQQQQQQ Messsages ", msgs);
        let msg = msgs.map((item, index) => {
            let previousMessage;
            if (index) {
                previousMessage = msgs[index - 1];
            }
            const uniqueKey = index;
            let content;
            if (item.SenderType === MessageSenderType.Visitor) {
                content = (
                    <React.Fragment>
                        {convertTimestampToDate(item.CreatedAt, index, previousMessage)}
                        <div className="reply-wrapper">
                            <div className="user reply-content">
                                <div
                                    className={`chat-message animated fadeIn
                                 ${item.MediaUrl && item.Content ? "imgMessageWithChat" : ""}
                                  ${!item.Content && item.MediaUrl ? "imgMessage" : ""} `}
                                    // style={{
                                    //     backgroundColor:
                                    //         widgetSettings.Appearance.VisitorMessageColor,
                                    //     color: widgetSettings.Appearance.VisitorMessageTextColor
                                    // }}
                                    style={{
                                        backgroundColor: `${item.MediaUrl ? "transparent" : widgetSettings?.BackgroundColor
                                            }`,
                                        color: colorIsLight(widgetSettings?.BackgroundColor) ? "#000000" : "#ffffff",
                                        boxShadow: `${item.MediaUrl ? "none" : "0 2px 4px 2px #e0e0e0"
                                            }`
                                    }}
                                >
                                    {renderMessageContent(item)}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                );
            } else if (item.SenderType === MessageSenderType.Agent) {
                if (item.IsAutomatedMessage != 1) {
                    content = (
                        <React.Fragment>
                            {convertTimestampToDate(item.CreatedAt, index, previousMessage)}
                            {item?.SenderType === MessageSenderType.Agent && item?.Type == 2 && item?.Agent?.Name && !(widgetSettings.TakeOperatorName == "No") ? (<div
                                className="reply-date"
                                style={{ color: "#84868d", fontSize: "12px" }}
                            >
                                {item?.Agent?.Name} joined the chat
                            </div>) : null}

                            {item?.SenderType === MessageSenderType.Agent && item?.Type == 2 && item?.Agent?.Name && widgetSettings.TakeOperatorName == "No" ? (<div
                                className="reply-date"
                                style={{ color: "#84868d", fontSize: "12px" }}
                            >
                                Agent joined the chat
                            </div>) : null}

                            {item?.AgentId && item?.Agent && item?.AgentId !== previousMessage?.AgentId && !(widgetSettings.TakeOperatorName == "No") ? (<div
                                style={{ color: "#84868d", paddingLeft: "20px", fontSize: "12px" }}
                            >
                                {item?.Agent?.Name}
                            </div>) : null}
                            {item?.SenderType === MessageSenderType.Agent && item?.Type == 2 ? null : (<div className="reply-wrapper">
                                {/* <div
                             className="user-name"
                              // style={{ color: widgetSettings.Appearance.LabelTextColor }}
                                style={{color: "#84868d"}} 
                                >
                                    {item.firstName}
                                </div> */}
                                <div className="admin reply-content">
                                    <div
                                        className={`chat-message animated fadeIn 
                                ${item.MediaUrl && item.Content ? "imgMessageWithChat" : ""}
                                ${!item.Content && item.MediaUrl ? "imgMessage" : ""} `}
                                        // style={{
                                        //     backgroundColor:
                                        //         widgetSettings.Appearance.VisitorMessageColor,
                                        //     color: widgetSettings.Appearance.VisitorMessageTextColor
                                        // }}
                                        style={{
                                            backgroundColor: `${item.MediaUrl ? "transparent" : "#f6f7f8"
                                                }`,
                                            color: "#000000",
                                            boxShadow: `${item.MediaUrl ? "none" : "0 2px 4px 2px #e0e0e0"
                                                }`
                                        }}
                                    >
                                        {renderMessageContent(item, true)}
                                    </div>
                                </div>
                            </div>
                            )}
                        </React.Fragment>
                    );
                } else {
                    content = (
                        <React.Fragment>
                            {convertTimestampToDate(item.CreatedAt, index, previousMessage)}
                            <div className="welcome-msg-sec">
                                <div className="chat-bubble">{item.Automation.IntroMessage}</div>
                                {item.AutomationMedia.length ? (
                                    <div className="quick-msg">
                                        {item.AutomationMedia.map((media) => (
                                            <a
                                                onClick={() =>
                                                    handleAutomationMediaDownload(
                                                        media.Id,
                                                        item.ChatId
                                                    )
                                                }
                                                key={media.Id}
                                                href={media.Path}
                                                target="_blank"
                                            >
                                                <span
                                                    className="q-bubble"
                                                    style={{
                                                        backgroundColor: widgetSettings.ButtonColor
                                                    }}
                                                >
                                                    {media.Name}
                                                </span>
                                            </a>
                                        ))}
                                    </div>
                                ) : null}
                            </div>
                        </React.Fragment>
                    );
                }
            }
            return (
                <div key={uniqueKey} className="reply padding-0">
                    {content}
                    {/* {chatData.Id ? this.renderReadReceipt(item.Data) : null} */}
                </div>
            );
        });
        return <div>{msg}</div>;
    };

    return (
        <React.Fragment>
            <div className="chat-box-body" id="chat-app">
                <div
                    className="chat-content"
                // style={
                //     agentJoined ? { height: "calc(100% - 12rem)" } : { height: "calc(100% - 6rem)" }
                // }
                >
                    <div
                        className="myScroll conversation fancy-scroll "
                        style={{ overflowY: "auto" }}
                        id="swiftchat-scroll-div"
                        onScroll={handleScroll}
                    >
                        {hasMore && messageLoading && messages.length > 0 ? (
                            <div className="message-loading-top">
                                <Loader size="sm" color="gray" />
                            </div>
                        ) : null}
                        <div className="conversation-wrapper">
                            {messages.length > 0 ? renderMessage() : null}

                            {<div className="renderUploadFile">{renderUploadedFile}</div> }

                            {automation.Automation && showOptionsContainer
                                ? renderOptionsContainer()
                                : null}

                            {/* uncomment this typing hint for a better typing hint */}
                            {/* {agentTyping ? renderTypingHint() : null} */}
                        </div>

                        {/* {hasMore && messageLoading && !messages.length ? (
                        <div className="message-loading">
                            <Loader color="gray" />
                        </div>
                    ) : null} */}

                        {/* {showDownButton && (
                        <div
                            className="conversation-end-btn animated fadeIn"
                            onClick={this.handleMoveDownClick}
                        >
                            <img src={AngleDownDouble} alt="angle-down-icon" />
                            {!!unseenMessages && (
                                <span
                                    className="text"
                                    style={{
                                        backgroundColor: widgetSettings.Appearance.TitleBarColor
                                    }}
                                >
                                    {unseenMessages}
                                </span>
                            )}
                        </div>
                    )} */}
                    </div>
                    {/* {automation.Automation && showOptionsContainer
                        ? renderOptionsContainer()
                        : null} */}

                    {/* Img Preview */}



                </div>
            </div>
            {!chatClosed ? (
                <RCChatWidgetMainViewChatField
                    ref={ChatFieldRef}
                    onFileChange={onFileChange}
                    handleImageClearClick={handleImageClearClick}
                    renderUploadedFile={renderUploadedFile}
                    showOptionsContainer={showOptionsContainer}
                    handleUserTyping={handleUserTyping}
                    showAutomationForm={showAutomationForm}
                    handleShowOptionsContainer={handleShowOptionsContainer}
                    agentTyping={agentTyping}
                    automationFormSubmitEver={automationFormSubmitEver}
                />
            ) : null}
            {agentData.Name ? (
                <div className="agent-chat-closed">
                    <p>Chat closed by agent {agentData.Name}</p>
                </div>
            ) : null}
        </React.Fragment>
    );
};

export default RCChatWidgetMainViewContent;

